import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { Location } from '@angular/common';
import { ForgotPasswordService } from './forgot-password.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-forgot-password',
  providers: [ForgotPasswordService],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  // managing subscription
  private subscription: Subscription = new Subscription();

  submitted = false;
  existingEmail = false;
  isLoadingOne = false;
  validateForm: FormGroup;

  constructor(private _location: Location,
    private cookieService: CookieService,
    private forgotService: ForgotPasswordService, private fb: FormBuilder, private router: Router, private notification: NzNotificationService) {
    this.validateForm = this.fb.group({
      userName: ['', { validators: Validators.required }],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  /**
   * function to dubmit the form
   */
  public submitForm() {
    for (const i in this.f) {
      this.f[i].markAsDirty();
      this.f[i].updateValueAndValidity();
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.validateForm.invalid) {
      return;
    }
    // login params
    const params = {
      'email': this.f.userName.value
    }
    // loading status
    this.isLoadingOne = true;
    // this.cookieService.delete('token');
    localStorage.removeItem('token');

    this.subscription.add(this.forgotService.forgotPass(params)
      .subscribe(
        res => {
          if (res['code'] === 200) {
            // navigatin url which is stored
            this.router.navigate(['/login']);
            // succesfull popup
            this.notification.create('success',
              `Congrats`,
              'Verification link has been sent to your email address.'
            );
            this.validateForm.reset();
          }
          // loading status
          this.isLoadingOne = false;
        },
        error => {
          this.existingEmail = false;
          // loading status
          this.isLoadingOne = false;
          // print the error to console
          console.error(error);
        })
    );
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  // userNameAsyncValidator = (control: FormControl) =>
  //   new Observable((observer: Observer<ValidationErrors | null>) => {
  //     setTimeout(() => {
  //       if (control.value === 'JasonWood') {
  //         observer.next({ error: true, duplicated: true });
  //       } else {
  //         observer.next(null);
  //       }
  //       observer.complete();
  //     }, 1000);
  //   });

  onBack() {
    this._location.back();
  }

}
