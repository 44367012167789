
import { NgModule } from '@angular/core';
import { NotificationPopupComponent } from './notification-popup.component';
import { SharedModule } from '../_common/shared.module';

@NgModule({
  declarations: [
    NotificationPopupComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    NotificationPopupComponent
  ]
})

export class NotificationPopupModule { }
