import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us.component';
import { ContactUsViewComponent } from './contact-us-view/contact-us-view.component';

const routes: Routes = [

    {
      path: '',
      data: {
        title: 'Contact Us Listing'
      },
      component: ContactUsComponent
    },
    {
      path: 'view/:id',
      data: {
        breadcrumb: 'View',
        title: 'View Contact Us'
      },
      component: ContactUsViewComponent
    }
  ];  

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class ContactUsRoutingModule { }