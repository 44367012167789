import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd';
import { SECRET_KEY} from '../_config/api.config';
import { EditProfileService } from './edit-profile.service';
import { CustomValidator } from '../_common/custom-validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  providers: [EditProfileService],
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy{
// secret key to encrypt the login credentails
private secretKey: string = SECRET_KEY;

  // managing subscription
  private subscription: Subscription = new Subscription();

  // submit loading
  public isLoadingOne = false;

  // form instance
  public validateForm: FormGroup;

  // form submit stauts
  public submitted: boolean = false;

  public userDetails: any = [];


  /**
   * function to reset form
   */
  public resetForm(): void {
    // e.preventDefault();
    this.validateForm.reset();
    for (const key in this.f) {
      this.f[key].markAsPristine();
      this.f[key].updateValueAndValidity();
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  /**
   * function to dubmit the form
   */
  public submitForm(): void {
    // for (const i in this.f) {
    //   this.f[i].markAsDirty();
    //   this.f[i].updateValueAndValidity();
    // }
    this.submitted = true;
    // stop here if form is invalid
    if (this.validateForm.invalid) {
      return;
    }
    // params send to change status
    const changePassParams = {
      'admin_id' : parseInt(this.userDetails['admin_id']),
      'first_name': this.f.first_name.value,
      'last_name': this.f.last_name.value
    };
    // submit loading
    this.isLoadingOne = true;
    // api call to update the status
    this.subscription.add(this.editProfileService.updateUserProfile(changePassParams)
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200) {
          // success modal when status updated
          this.notification.success(
            'Profile Updated',
            'Your details has been saved successfully!'
          );
          this.router.navigateByUrl('/dashboard');
        }
        // submit loading
        this.isLoadingOne = false;
      }, (error) => {
        // submit loading
        this.isLoadingOne = false;
        // print the error on console
        console.error(error);
      }));
  }

  // error messages
  public validation_messages = {
    'first_name': [
      { type: 'required', message: 'Please enter first name!' },
      { type: 'invalidString', message: 'Invalid first name!' },
      { type: 'whitespace', message: 'Invalid first name!' },
      { type: 'maxlength', message: 'First name should not be more than 50 characters long!' }
    ],
    'last_name': [
      { type: 'required', message: 'Please enter last name!' },
      { type: 'invalidString', message: 'Invalid last name!' },
      { type: 'whitespace', message: 'Invalid last name!' },
      { type: 'maxlength', message: 'Last name should not be more than 50 characters long!' }
    ],
  }


  constructor(
    private fb: FormBuilder,
    private editProfileService: EditProfileService,
    private notification: NzNotificationService,
    private router: Router,
  ) {
    // form group instancec
    this.validateForm = this.fb.group({
      first_name: new FormControl(null, {
        validators: Validators.compose([
          Validators.maxLength(40),
          Validators.required,
          CustomValidator.noWhiteSpaceValidator,
          CustomValidator.blockSpecialCharacter
        ]),
        updateOn: 'blur'
      }),
      last_name: new FormControl(null, {
        validators: Validators.compose([
          Validators.maxLength(40),
          Validators.required,
          CustomValidator.noWhiteSpaceValidator,
          CustomValidator.blockSpecialCharacter
          // Validators.pattern('^(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]+$')
        ]), 
        updateOn: 'blur'
      }),
    });
  }

  ngOnInit() {
    this.getProfileDetails();
  }

  public getProfileDetails() {
    this.subscription.add(this.editProfileService.getUserProfileDetails()
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200) {
          this.userDetails = response['data'];
          this.validateForm.setValue({
            first_name: this.userDetails['first_name'],
            last_name: this.userDetails['last_name'],
          })
        }
      }, (error) => {
        // submit loading
        this.isLoadingOne = false;
        // print the error on console
        console.error(error);
      }));
  }

  // unsubscriobe all the apis
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
