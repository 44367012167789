import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { AuthenticationService } from '../_services';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService,
    private cookieService: CookieService,
    private activeRoute: ActivatedRoute, ) {


    this.activeRoute.queryParams.subscribe(params => {
      const customerKey = Object.keys(params)[0];
      if (customerKey === 'customer_token') {
        const staticToken = 'd1613b6a-ff97-11e8-8eb2-f2801f1b9fd1';
        // this.cookieService.set('token', staticToken);
        localStorage.setItem('token', staticToken);
        console.log(staticToken, '  staticToken');
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // const currentUser = this.authService.currentUserValue;
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user_details') ? JSON.parse(localStorage.getItem('user_details')) : null;
    // const token = this.cookieService.get('token');
    let headers = {};
    if (token) {
      headers['Authorization'] = token;
    } else {
      headers['Authorization'] = "e49802a8-efcd-49ba-8460-882353e18703";
    }
    headers['Cache-Control'] = 'no-cache';
    headers['Pragma'] = 'no-cache';
    headers['languagecode'] = 'en';
    // headers['Content-Type'] = 'application/json';
    if (userData && userData.type == 'V') {
      headers['usertype'] = 'vendor';
    }
    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request);
  }
}
