import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../../_config/api.config';

@Injectable()
export class ContactUsViewService {
  details = URLS.contactUsDetails;
  constructor(private http: HttpClient) {}

  getContactUsDetail(id: any): Observable<{}> {
    return this.http.get(`${this.details}/${id}`);
  }
}
