import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddEditCmsService } from '../cms/add-edit-cms/add-edit-cms.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-privacy-policy',
  providers: [AddEditCmsService],
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  title: string = '';
  description: string = '';
  updatedAt: any = '';

  constructor(
    private cmsService: AddEditCmsService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  public getData(): void {
    this.subscription.add(this.cmsService
      .getPrivacyPolicy()
      .subscribe((data: any) => {
        if (data['code'] === 200) {
          this.title = data.data.cms_title;
          this.description = data.data.cms_description;
          this.updatedAt = new Date(data.data.updated_at);
        }
      }, (error) => {
        console.error(error);
      })
    );
  }

}
