import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd';
import { ChangePasswordService } from './change-password.service';
import * as crypto from 'crypto-js';
import { SECRET_KEY } from '../_config/api.config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  providers: [ChangePasswordService],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnDestroy {
  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;

  // password visibility toggle
  public passwordVisible = false;
  public newpasswordVisible = false;

  // managing subscription
  private subscription: Subscription = new Subscription();

  // submit loading
  public isLoadingOne = false;

  // form instance
  public validateForm: FormGroup;

  // form submit stauts
  public submitted: boolean = false;


  /**
   * function to reset form
   */
  public resetForm(): void {
    // e.preventDefault();
    this.validateForm.reset();
    for (const key in this.f) {
      this.f[key].markAsPristine();
      this.f[key].updateValueAndValidity();
    }
  }

  /**
   * function to validate password on input
   */
  public validateConfirmPassword(): void {
    setTimeout(() => this.f.confirm.updateValueAndValidity());
  }

  // confirm password validator
  public confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.f.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  /**
   * function to dubmit the form
   */
  public submitForm(): void {
    for (const i in this.f) {
      this.f[i].markAsDirty();
      this.f[i].updateValueAndValidity();
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.validateForm.invalid) {
      return;
    }
    // params send to change status
    const changePassParams = {
      'old_password': this.f.oldPassword.value,
      'new_password': this.f.password.value
    };
    const cipherData = crypto.AES.encrypt(JSON.stringify(changePassParams), this.secretKey).toString();
    // submit loading
    this.isLoadingOne = true;
    // api call to update the status
    this.subscription.add(this.changePasswordService.updateEmail({ 'data': cipherData })
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200) {
          // success modal when status updated
          this.notification.success(
            'Password Changed',
            'Your password has been changed successfully.Please logout and login again to see effect!'
          );
          // reset form when form is succesfullt submitted
          this.resetForm();
          this.router.navigateByUrl('/dashboard');
        } else if (response['code'] === 402) {
          this.notification.error('Error',
              response['message']
          );
        }
        // submit loading
        this.isLoadingOne = false;
      }, (error) => {
        // submit loading
        this.isLoadingOne = false;
        // print the error on console
        console.error(error);
      }));
  }

  // error messages
  public validation_messages = {
    'old_password': [
      { type: 'required', message: 'Please input your old password!' },
      { type: 'minlength', message: 'Your Password must be at least 8 characters long' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Please confirm your password!' },
      { type: 'confirm', message: 'Password is inconsistent!' }
    ],
    'password': [
      { type: 'required', message: 'Please input your password!' },
      { type: 'minlength', message: 'Your Password must be at least 8 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, one number and one special character' }
    ],
  }


  constructor(private fb: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private notification: NzNotificationService,
    private router: Router,) {
    // form group instancec
    this.validateForm = this.fb.group({
      oldPassword: new FormControl(null, {
        validators: Validators.compose([
          Validators.minLength(8),
          Validators.required]),
      }),
      password: new FormControl(null, {
        validators: Validators.compose([
          Validators.minLength(8),
          Validators.maxLength(12),
          Validators.required,
          Validators.pattern('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')
        ]),
      }
      ),
      confirm: new FormControl(null, { validators: this.confirmValidator })
    });
  }

  // unsubscriobe all the apis
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
