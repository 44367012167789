
import { NgModule } from '@angular/core';
import { SharedModule } from '../_common/shared.module';
import { SeatingArrangementComponent } from './seating-arrangement.component';
import { BrowserModule } from '@angular/platform-browser';
// import { TooltipModule } from 'ngx-bootstrap';




@NgModule({
  declarations: [
    SeatingArrangementComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    // TooltipModule.forRoot(),

  ]
})
export class SeatingArrangementModule { }
