// export class ColumnSetting {
//   primaryKey: string;
//   header?: string;
//   // format?: string;
//   // alternativeKeys?: string[];
//   sort?: boolean;
//   actions?: boolean;
// }
export class ColumnMap {
  primaryKey?: string;
  header?: string;
  // private _format: string;
  // alternativeKeys?: string[];
  sort?: boolean;
  is_featured?:boolean;
  catfeat?:boolean;
  validuser?:boolean;
  is_verified_profile?:boolean;
  is_visible?:boolean;
  status?:boolean;
  action?: boolean;
  edit?: boolean;
  image?: boolean;
  number?: boolean;
  maxLength?: number;
  btn?:boolean;

constructor ( settings ) {
      this.primaryKey = settings.primaryKey;
      this.header = settings.header;
      // this.format = settings.format;
      // this.alternativeKeys = settings.alternativeKeys;
      this.catfeat = settings.catfeat;
      this.is_featured = settings.is_featured_event;
      this.is_visible = settings.is_visible;
      this.validuser = settings.validuser;
      this.is_verified_profile = settings.is_verified_profile;
      this.btn = settings.btn;
      this.status = settings.status;
      this.sort = settings.sort;
      this.action = settings.action;
      this.edit = settings.edit;
      this.image = settings.image;
      this.number = settings.number;
      this.maxLength = settings.maxLength;
  }
//   set header(setting: string) {
//       this._header = setting ?
//           setting :
//           this.primaryKey.slice(0, 1).toUpperCase() +
//               this.primaryKey.replace(/_/g, ' ' ).slice(1)
//   }
//   get header() {
//       return this._header;
//   }
//   set format(setting: string) {
//       this._format = setting ? setting : 'default';
//   }
//   get format() {
//       return this._format;
//   }

// access = function ( object: any ) {
//       if (object[this.primaryKey] || !this.alternativeKeys) {
//           return this.primaryKey;
//       }
//       for (let key of this.alternativeKeys) {
//           if (object[key]) {
//               return key;
//           }
//       }
//       return this.primaryKey;
//   }
}
