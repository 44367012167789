import { FormGroup } from "@angular/forms";

export class CustomValidator {
  // Validates URL
  static urlValidator(url): any {
   if (url.value === null || url.value === "") return null;
   if (url.pristine) {
        return null;
     }
     const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
     url.markAsTouched();
     if (URL_REGEXP.test(url.value)) {
        return null;
     }
     return {
        invalidUrl: true
     };
  }
  // Validates passwords
  static matchPassword(group: FormGroup): any {
     const password = group.controls.password;
     const confirm = group.controls.confirm;
     if (password.pristine || confirm.pristine) {
        return null;
     }
     group.markAsTouched();
     if (password.value === confirm.value) {
        return null;
     }
     return {
        invalidPassword: true
     };
  }
  // Validates numbers
  static numberValidator(number): any {
     if (number.pristine) {
        return null;
     }
     const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
     number.markAsTouched();
     if (NUMBER_REGEXP.test(number.value)) {
        return null;
     }
     return {
        invalidNumber: true
     };
  }
  // Validates US SSN
  static ssnValidator(ssn): any {
     if (ssn.pristine) {
        return null;
     }
     const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
     ssn.markAsTouched();
     if (SSN_REGEXP.test(ssn.value)) {
        return null;
     }
     return {
        invalidSsn: true
     };
  }
  // Validates US phone numbers
  static phoneValidator(number): any {
     if (number.pristine) {
        return null;
     }
     const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
     number.markAsTouched();
     if (PHONE_REGEXP.test(number.value)) {
        return null;
     }
     return {
        invalidNumber: true
     };
  }
  // Validates zip codes
  static zipCodeValidator(zip): any {
   if (zip.value === null || zip.value === "") return null;
      if (zip.pristine) {
        return null;
      }
     const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
     zip.markAsTouched();
     if (ZIP_REGEXP.test(zip.value)) {
        return null;
     }
     return {
        invalidZip: true
     };
  }
  // Vlidates password pattern
  static passwordPatternValidator(password): any {
    if (password.pristine) {
       return null;
    }
    const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    password.markAsTouched();
    if (PASSWORD_REGEXP.test(password.value)) {
       return null;
    }
    return {
       invalidPassword: true
    };
 }

  // Validates white space
  static noWhiteSpaceValidator(string): any {
      if (string.value === null || string.value === "") return null;
      if (string.pristine) return null;
      string.markAsTouched();
      const isWhitespace = (string.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }
  
  // Validates double quotes
  static noQuotesValidator(string): any {
    if (string.value) {
      let isQuateInvalid = false;
      if (string.value.includes('\"') || string.value.includes("\'")) {
        isQuateInvalid = true;
      }
      return isQuateInvalid ? {'quotes' : true}: null;
    }
  }

  // Vlidates facebook pattern
   static facebookPatternValidator(facebook): any {
      if (facebook.value === null || facebook.value === "") return null;
      if (facebook.pristine) {
         return null;
      }
      const FACEBOOK_REGEXP = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/i;
      facebook.markAsTouched();
      if (FACEBOOK_REGEXP.test(facebook.value)) {
         return null;
      }
      return {
         invalidFacebook: true
      };
   }

   // Vlidates Twiter pattern
   static twiterPatternValidator(twiter): any {
      if (twiter.value === null || twiter.value === "") return null;
      if (twiter.pristine) {
         return null;
      }
      const TWITER_REGEXP = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
      twiter.markAsTouched();
      if (TWITER_REGEXP.test(twiter.value)) {
         return null;
      }
      return {
         invalidTwiter: true
      };
   }

   // Vlidates Instagram pattern
   static instagramPatternValidator(instagram): any {
      if (instagram.value === null || instagram.value === "") return null;
      if (instagram.pristine) {
         return null;
      }
      const INSTAGRAM_REGEXP = /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/i;
      instagram.markAsTouched();
      if (INSTAGRAM_REGEXP.test(instagram.value)) {
         return null;
      }
      return {
         invalidInstagram: true
      };
   }

   // Do not allow special characters
   static blockSpecialCharacter(string): any {
      if (string.value === null || string.value === "") return null;
      if (string.pristine) return null;
      const ONLY_ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9 ]+$/i;
      string.markAsTouched();
      if (ONLY_ALPHA_NUMERIC_REGEX.test(string.value)) {
         return null;
      }
      return {
         invalidString: true
      }
  }

}
