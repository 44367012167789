import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { ColumnMap } from '../layout.model';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { InlineTableLayoutService } from './inline-table-layout.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'inline-app-table-layout',
  providers: [InlineTableLayoutService],
  templateUrl: './inline-table-layout.component.html',
  styles: [`
      .uploadButton: {
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      margin-right: 5px
    }
  `],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        padding: '24px',
        maxHeight: '800px'
      })),
      state('closed', style({
        opacity: 0,
        padding: 0,
        maxHeight: 0,
        overflow: 'hidden'
      })),
      transition('open => closed', [
        animate('300ms ease-in')
      ]),
      transition('closed => open', [
        animate('300ms ease-out')
      ]),
    ])
  ]
})
export class InlineTableLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: any[];
  @Input() apiUrl: any;
  @Input() actions: any;
  @Input() widthConfig: any[];
  @Input() scrollConfig: any;
  @Input() search: any[];
  @Input() queryParams: any[] = [];
  @Input() addModule: any;
  @Input() featureData: any;

  fixedHeader: Boolean = true;
  multiOptions: any;
  // column key
  columnMaps: ColumnMap[];
  // filter panel initial state
  isOpen = false;
  // managing subscription
  private subscription: Subscription = new Subscription();
  // confirmation modal instance
  private confirmModal: NzModalRef;
  // table default params
  public tableConfig = {
    'page': 1,
    'limit': 10
  };
  // table data loading status
  public loading = false;
  // array to hold the table data
  public listOfAllData: any[] = [];
  // object to store custom table configuration
  public tableDataConfig = {};
  //form instance
  public validateForm: FormGroup;

  public autocompleteData: any = [];
  public currentRecordData: any = {};
  public isEditRowOpen: boolean = false;

  public errorMessages: any = [];
  /*** filter panel toggle function**/
  toggle() {
    this.isOpen = !this.isOpen;
  }


  /**
   * function to implemnt sorting on table
   * @param key to hold the table header key
   * @param value to hold the table sorting type
   */
  public sort(sort: { key: string; value: string }): void {
    // preparing dynamic params based on sorting
    this.tableConfig['sort_by'] = sort.key;
    this.tableConfig['sort_type'] = sort.value === 'descend' ? 'desc' : 'asc';

    // if no results found then don't call the api
    if (this.listOfAllData.length) {
      this.getData(true);
    }
  }

  /** function to reset the form  */
  public resetForm(): void {
    // reset the form
    this.validateForm.reset();
    // if reset set the page to 1
    this.tableConfig['page'] = 1;
    // list all data
    this.getData(true);
  }

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private tableLayoutService: InlineTableLayoutService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private activeRoute: ActivatedRoute,
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  /**
  * function to update the status
  * @param data hold the table data instance
  */
  public updateTable(data) {
    // params send to change status
    let httpParams: { [k: string]: any } = {};
    httpParams[this.queryParams['status']] = (data['status'] === 0 ? 1 : 0);
    httpParams[this.queryParams['page_id']] = data[this.queryParams['page_id']];
    // status loading  indicator
    data['switchLoading'] = true;
    // table data loading status
    this.loading = true;
    // api call to update the status
    this.subscription.add(this.tableLayoutService.updateStatus(this.apiUrl.updateStatus, httpParams).subscribe((res) => {
      // if success then go in loop
      if (res['code'] === 200) {
        // change status
        data['status'] = !data['status'];
        // success modal when status updated
        this.notification.create(
          'Success',
          'Status changed',
          'Status has been updated successfully.'
        );

      }
      // destroy the confirm modal instance
      this.confirmModal.destroy();
      // status loading  indicator
      data['switchLoading'] = false;
      // table data loading status
      this.loading = false;
    }, (error) => {
      // destroy the confirm modal instance
      this.confirmModal.destroy();
      // status loading  indicator
      data['switchLoading'] = false;
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }

  /**
   * function to confirm the status
   * @param data table data instance
   */
  public showConfirm(data: any): void {
    this.confirmModal = this.modal.confirm({
      // ${data.is_active === 0 ? 'Inactive' : 'Active'}
      nzTitle: `Do you want to change the status?`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // function call to update the status
          this.updateTable(data);
        }).catch(() => { console.log('Oops errors!') }),
      nzOnCancel: () => { this.loading = false; data['switchLoading'] = false; }
    });
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.fixedHeader = false;
        } else {
          this.fixedHeader = true;
        }
      });
    // function call to load data when page loads
    this.getData(true);
  }

  public getAutocompleteData(value: string) {
    let param = {
      dropdown: true,
      leader_name: value
    };
    // api call to get the table data
    this.subscription.add(this.tableLayoutService
      .getAutocompleteData(this.apiUrl.autocomplete, param)
      .subscribe((responce: any) => {
        if (responce['code'] === 200) {
          this.autocompleteData = responce['data'];
        }
      })
    )
  }

  /** Format date in required format
   * @param dates object containing start and end date
  */
  public formatDate(dates) {
    dates.forEach((date, index) => {
      if (typeof date !== 'string') {
        const dd = (date.getDate().toString().length === 1) ? '0' + date.getDate() : date.getDate();
        let mm = date.getMonth() + 1;
        mm = (mm.toString().length === 1) ? '0' + mm : mm;
        const yyyy = date.getFullYear();
        dates[index] = yyyy + '-' + mm + '-' + dd;
      }
    });
  }

  /**
   * @param fromFilter If user search from advance filter do not pass page number
   */
  public getData(fromFilter: boolean): void {
    // httpParams for handling dynamic query params
    let httpParams = new HttpParams();
    let copyOfTableConfig = this.tableConfig;
    // query params for pagination
    if (!fromFilter) delete copyOfTableConfig['page'];
    for (const key of Object.keys(copyOfTableConfig)) {
      httpParams = httpParams.append(key, copyOfTableConfig[key]);
    }
    // get form keys and value
    const formKeys = Object.keys(this.f),
      formValue = this.f,
      searchBy = [],
      searchValue = [];
    // create query params based on form value
    for (let keys of formKeys) {
      const appendValues = formValue[keys]['value'];
      if (appendValues) {
        if (keys.includes('date') === true) {
          this.formatDate(appendValues);
        }
        searchBy.push(keys);
        if (typeof appendValues === 'string') {
          searchValue.push(appendValues.trim().replace(/,/g, '#x2c'));
        } else {
          searchValue.push(appendValues);
        }
      }
    }
    if (searchBy.length) httpParams = httpParams.append('search_by', searchBy.toString());
    if (searchValue.length) httpParams = httpParams.append('search', searchValue.toString());

    // table data loading status
    this.loading = true;
    // api call to get the table data
    this.subscription.add(this.tableLayoutService.getTableData(this.apiUrl.list, httpParams).subscribe((res) => {
      // if data present then only goes in the ;oop
      if (res['code'] === 200) {
        // displaying data in table
        this.listOfAllData = res['data']['result'];
        if(this.featureData=== "cat_list"){
          this.listOfAllData.forEach((element, index) => {
              if(index > 1){
                element['catfeat'] = 'Featured';
              } else{
                element['catfeat'] = 'Not Featured';
              }
            });
          }
        //logic to manipulate field keys for queryparams
        for (const data of this.listOfAllData) {
          data['isNewRow'] = false;
          data['isInlineEdit'] = false;
        }
        // storing the totalrecord for pagination
        this.tableDataConfig['totalRecords'] = res['data']['totalRecords'];
      }
      // table data loading status
      this.loading = false;
    }, (error) => {
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }

  public onImageUpload(event, currentRecord, curentProperty) {
    const file = event.target.files[0];
    if (file.type.split('/')[0] === 'image') {
      var reader = new FileReader();
      reader.onload = (e) => {
        let reader = e.target;
        currentRecord[curentProperty] = reader['result'];
        currentRecord['image_extension'] = '.' + file.type.split('/')[file.type.split('/').length - 1];
      }
      reader.readAsDataURL(file);
    } else {
      event.target.value = null;
      this.notification.error('Error', 'Please Upload Only Image (.png or .jpeg)');
    }
  }

  public navigateRow(viewName: string, dataSource: number): void {
    this.listOfAllData.forEach(data => {
      if (data['isInlineEdit']) {
        this.onCancel(data);
      }
    })
    this.currentRecordData = Object.assign({}, dataSource);
    dataSource['isInlineEdit'] = true;
    // this.router.navigate([viewName, dataSource], { relativeTo: this.activeRoute });
  }

  public onCancel(currentRecord) {
    /**Clear validation messages */
    this.errorMessages = [];
    /**Delete current record in case of new row add scenario or assign previous data back in case of edit */
    if (currentRecord.isNewRow) {
      this.listOfAllData.shift();
    } else {
      for (let record in currentRecord) {
        currentRecord[record] = this.currentRecordData[record];
      }
      this.isEditRowOpen = false;
      this.currentRecordData = {};
    }
  }

  public formValidation(form) {
    this.errorMessages = [];
    const fields = Object.keys(form.controls);
    for (const field of fields) {
      if (form.controls[field]['errors'] !== null) {
        form.controls[field]['touched'] = true;
        // Prepare Message For Required Condition
        if (form.controls[field]['errors']['required']) {
          const msg = field.split("_")[field.split("_").length - 1] + ' ' + 'is required';
          this.errorMessages.push(msg);
        }
        // Prepare Message For Pattern Condition
        if (form.controls[field]['errors']['pattern']) {
          const msg = field.split("_")[field.split("_").length - 1] + ' ' + 'should be in proper format';
          this.errorMessages.push(msg);
        }
        // // Prepare Message For length Condition
        // if (map['maxLength']) {
        //   const msg = field.split("_")[field.split("_").length - 1] + ' ' + 'should not be more than 50 characters long';
        //   this.errorMessages.push(msg);
        // }
      }
    }
    return (this.errorMessages.length) ? false : true
  }

  public onSubmit(form, currentRecord) {
    const isFormValid = this.formValidation(form);
    if (isFormValid) {
      let param = {};
      for (let i = 0; i < this.settings.length; i++) {
        if (this.settings[i].edit === true && currentRecord[this.settings[i]['primaryKey']]) {
          /**handling number and string param seperatly */
          if (Number(currentRecord[this.settings[i]['primaryKey']])) {
            /**handling country code case from manage country */
            if (currentRecord[this.settings[i]['primaryKey']].length && currentRecord[this.settings[i]['primaryKey']].indexOf('+') === -1) {
              param[this.settings[i]['primaryKey']] = parseInt(currentRecord[this.settings[i]['primaryKey']], 10);
            } else {
              param[this.settings[i]['primaryKey']] = currentRecord[this.settings[i]['primaryKey']];
            }
          } else {
            param[this.settings[i]['primaryKey']] = currentRecord[this.settings[i]['primaryKey']];
          }
        }
      }
      if (currentRecord['image_extension']) {
        param['category_icon_extension'] = currentRecord['image_extension'];
      }
      // table data loading status
      this.loading = true;
      if (currentRecord['isNewRow']) {
        this.addRecord(currentRecord, param);
      } else {
        param[this.queryParams['page_id']] = currentRecord[this.queryParams['page_id']];
        this.updateRecord(currentRecord, param);
      }
    }
  }

  public addRecord(currentRecord, param) {
    // api call to get the table data
    this.subscription.add(this.tableLayoutService.addTableData(this.apiUrl.addData, param).subscribe((res) => {
      // table data loading status
      this.loading = false;
      if (res['code'] === 200) {
        this.isEditRowOpen = false;
        this.currentRecordData = {};
        currentRecord['isInlineEdit'] = false;
        // success modal when status updated
        this.notification.success('Success', res['message']);
        this.getData(true);
      }
    }, (error) => {
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }

  public updateRecord(currentRecord, param) {
    // api call to get the table data
    this.subscription.add(this.tableLayoutService.updateTableData(this.apiUrl.updateData, param).subscribe((res) => {
      // table data loading status
      this.loading = false;
      if (res['code'] === 200) {
        this.isEditRowOpen = false;
        this.currentRecordData = {};
        currentRecord['isInlineEdit'] = false;
        // success modal when status updated
        this.notification.success('Success', res['message']);
      }
    }, (error) => {
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }

  // To redirect on add page.
  public onAdd(): void {
    if (this.addModule['inlineAdd']) {
      // restrict add module if already one add or edit module not submitted
      if ((this.listOfAllData.length && this.listOfAllData[0].isNewRow !== true && Object.keys(this.currentRecordData).length === 0) || !this.listOfAllData.length) {
        const newEmptyRow = {
          'isInlineEdit': true,
          'isNewRow': true
        }
        for (let i = 0; i < this.settings.length; i++) {
          if (this.settings[i].edit) {
            newEmptyRow[this.settings[i]['primaryKey']] = null;
          }
        }
        this.listOfAllData.unshift(newEmptyRow);
      } else if (Object.keys(this.currentRecordData).length) {
        this.isEditRowOpen = true;
      }
    } else {
      this.router.navigate([this.addModule.url]);
    }
  }

  ngOnChanges() {
    if (this.settings) {
      this.columnMaps = this.settings.map(col => new ColumnMap(col));
    } else {
      this.columnMaps = Object.keys(this.listOfAllData[0]).map(key => {
        return new ColumnMap({ primaryKey: key });
      });
    }
    // prepare form group
    this.validateForm = this.fb.group({});
    if (this.search) {
      for (let i = 0; i < this.search.length; i++) {
        this.validateForm.addControl(this.search[i].fieldKey, new FormControl());
      }
    }
  }

  deleteData(dataId: any): void {
    //todo
  }

  // on destroy unsubscribe all the subscription
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
