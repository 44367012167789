import { Component, OnInit } from '@angular/core';
import { URLS } from '../_config/api.config';

@Component({
  selector: 'app-manage-event-report',
  templateUrl: './manage-event-report.component.html',
  styleUrls: ['./manage-event-report.component.scss']
})
export class ManageEventReportComponent implements OnInit {

  constructor() { }

  column_settings = [
    { primaryKey: 'reported_date', header: 'Date', sort: true},
    { primaryKey: 'reported_by', header: 'Reported By', sort: true},
    { primaryKey: 'reported_event', header: 'Reported Event', sort: true},
    { primaryKey: 'report_description', header: 'Description', sort: true},
    { primaryKey: 'email', header: 'Email'},
    { primaryKey: 'is_reported', header: 'Status', status: true },
    { primaryKey: '', header: 'Action', action: true}
  ];
  apiUrl = {
    list: URLS.inappropriateeventsList,
    updateStatus: URLS.inappropriateeventsUpdatestatus
  };
  actions = {
    view: false,
    edit: false,
    emailSend: true,
    delete: true,
    updateStatus: true,
  };
  widthConfig = ['150px', '150px', '150px', '150px', '150px', '180px'];
  scrollConfig = { x: '800px', y: '100%' };
  queryParams = { status: 'is_reported', page_id: 'event_id'};
  table_search = [
    {fieldKey: 'date_range', fieldName: 'Date', range: true},
    {fieldKey: 'reported_by', fieldName: 'Reported By', input: true},
    {fieldKey: 'reported_event', fieldName: 'Reported Event', input: true},
  ]

  // Manipulating Add User button
  // text which is going to display on button
  // URL will be redirect on specific page
  // isVisible checking whether button will be visible or not based on Boolean value passed.
  // public addUser: any = {
  //   text: 'Add User',
  //   url: '/manage-user/add',
  //   isVisible: true
  // }

  
  ngOnInit() {
  }

}