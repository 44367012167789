// Angular Library Imports
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public isNoMuduleAccess: boolean = true;

  constructor(private commonService: CommonService) {

  }
  ngOnInit() {
    this.commonService.eventObservable.subscribe(response => {
      if (response['event'] === 'isNoMuduleAccess') {
        this.isNoMuduleAccess = response['data'];
      }
    })
    // this.isNoMuduleAccess = Boolean(localStorage.getItem('isNoMuduleAccess'));  
  }
}
