import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class InlineTableLayoutService {

  getTableData(url , dataParams?:any): Observable<{}> {
    return this.http.get(url , {
      params: dataParams
    });
  }
  getAutocompleteData(url, dataParams): Observable<{}> {
    return this.http.get(url , {
      params: dataParams
    });
  }
  updateStatus(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  updateTableData(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  addTableData(url, params : any): Observable<{}> {
    return this.http.post(url , params);
  }


  deleteData(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  constructor(private http: HttpClient) {}
}
