import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as crypto from 'crypto-js';
import { URLS, SECRET_KEY } from '../_config/api.config';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;

  // observable user details
  // public currentUserSubject: BehaviorSubject<any>;

  // onserveable logged in status
  public loggedInStatus;

  // logged in user tpe
  // public currentUser: Observable<any>;

  // login api url
  private loginUrl = URLS.login;
  private vendorloginUrl = URLS.vendorlogin;

  // logout api url
  private logoutUrl = URLS.logout;
  constructor(private http: HttpClient, private cookieService: CookieService) {
    // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  /**
* if we have token the user is loggedIn
* @returns {boolean}
*/
  private hasToken(): boolean {
    return !!localStorage.getItem('token');
    // return !!this.cookieService.get('token');
  }

  /**
  *
  * @returns {Observable<T>}
  */
  public isLoggedIn() {
    return this.loggedInStatus.asObservable();
  }

  /**
*  Login the user then tell all the subscribers about the new status
*/
  public login(loginData: any) {
    const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
    // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
    return this.http.post<any>(`${this.loginUrl}`, { 'data': cipherData })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.code === 200) {
          localStorage.setItem('token', user.data.token);
          localStorage.setItem('user_details', JSON.stringify(user.data));
          // this.cookieService.set('token', user.data);
          this.loggedInStatus.next(true);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          //  this.currentUserSubject.next(user);
          return user;
        } else if (user && user.code === 405) {
          return user;
        } else {
          return false;
        }
      }));
  }

  public vendorlogin(loginData: any) {
    const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
    // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
    return this.http.post<any>(`${this.vendorloginUrl}`, { 'data': cipherData })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.code === 200) {

          localStorage.setItem('token', user.data.token);
          localStorage.setItem('user_details', JSON.stringify(user.data));
          // this.cookieService.set('token', user.data);
          this.loggedInStatus.next(true);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          //  this.currentUserSubject.next(user);
          return user;
        } else if (user && user.code === 405) {
          return user;
        } else {
          return false;
        }
      }));
  }

  public logout() {
    this.loggedInStatus.next(false);
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('user_details');
    return this.http.get<any>(`${this.logoutUrl}`);
    //  this.currentUserSubject.next(null);
  }
}
