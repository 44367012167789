import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, HttpErrorInterceptor } from './_helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CKEditorModule } from 'ng2-ckeditor';
/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzLayoutModule, NzBackTopModule, NzModalModule } from 'ng-zorro-antd';
/** config ng-zorro-antd i18n **/
import { NZ_I18N, en_US } from 'ng-zorro-antd';
// import { IconDefinition } from '@ant-design/icons-angular';
// import * as AllIcons from '@ant-design/icons-angular/icons';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { DynamicBreacrumbModule } from './dynamic-breacrumb/dynamic-breacrumb.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NotificationPopupModule } from './notification-popup/notification-popup.module';
import { ViewNotificationModule } from './view-notification/view-notification.module';
import { UserSettingsPopupModule } from './user-settings-popup/user-settings-popup.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from './_common/shared.module';
import { CanDeactivateGuard } from './_guards/can-deactivate-guard';
import { EditProfileModule } from './edit-profile/edit-profile.module';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './_services/common.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ManageEventReportModule } from './manage-event-report/manage-event-report.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { FeatureEventComponent } from './feature-event/feature-event.component';
import { SettingModule } from './setting/setting.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NotificationModule } from './notification/notification.module';
import { SeatingArrangementModule } from './seating-arrangement/seating-arrangement.module';

// import { ContactUsComponent } from './contact-us/contact-us.component';
// const antDesignIcons = AllIcons as {
//   [key: string]: IconDefinition;
// };
// const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
registerLocaleData(en);

const PAGE_COMPONENTS = [
  AppComponent,
  LoginComponent,
  LayoutComponent,
  PageNotFoundComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  PrivacyPolicyComponent,
]

const PAGE_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  SharedModule,
  NzLayoutModule,
  NzBackTopModule,
  NzTagModule,
  CKEditorModule,
  NgxChartsModule,
  LayoutModule,
  AppRoutingModule,
  ViewNotificationModule,
  NotificationPopupModule,
  UserSettingsPopupModule,
  EditProfileModule,
  ChangePasswordModule,
  DynamicBreacrumbModule,
  DashboardModule,
  ManageEventReportModule,
  ContactUsModule,
  SettingModule,
  NotificationModule,
 SeatingArrangementModule
]
@NgModule({
  declarations: [...PAGE_COMPONENTS, FeatureEventComponent],
  imports: [...PAGE_MODULES],
   providers   : [
     CanDeactivateGuard,
     CookieService,
     CommonService,
    //  { provide: NZ_ICONS, useValue: icons }
    /** config ng-zorro-antd i18n (language && date) **/
     { provide: NZ_I18N, useValue: en_US },
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
