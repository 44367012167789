import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { SettingService } from './setting.service';

@Component({
  selector: 'app-setting',
  providers:[SettingService],
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  featuredPrice: any;
  featuredDuration: any;
  supportPhone: any;
  imageSrc: string;
  images: any[] = [];

  public SettingList: any;
  public settingId: any;
  private subscription: Subscription = new Subscription();

  constructor(
    public notification: NzNotificationService,
    public settingService: SettingService
  ) { }

  ngOnInit() {
    this.getSetting();
  }

  getSetting(){
    this.subscription.add(
      this.settingService.getSettingData().subscribe((res)=>{
        if(res['code'] === 200){
          this.SettingList = res['data'];
          this.settingId = this.SettingList['setting_id'];
          this.featuredPrice = this.SettingList['featured_price'];
          this.featuredDuration = this.SettingList['featured_duration'];
          this.supportPhone = this.SettingList['support_phone'];
        }
        else{
          this.notification.error('Error',res['message']);
        }
      },
      (err)=>{
        console.log(err);
      })
    )
  }

  submitSetting(){
    if(this.featuredPrice == null || this.featuredPrice == ""){
      this.notification.error('Error','Enter featured price');
      return;
    }
    if(this.featuredDuration == null || this.featuredDuration == ""){
      this.notification.error('Error','Enter featured duration');
      return;
    }
    if(this.supportPhone == null || this.supportPhone == ""){
      this.notification.error('Error','Enter Support Phone');
      return;
    }
    if (isNaN(Number(this.supportPhone)) || this.supportPhone.length < 8 || this.supportPhone.length > 12) {
      this.notification.error('Error', 'Support Phone must be a number with 8 to 12 digits');
      return;
    }
   
    //multiple image
    let file = []
    for (let i = 0; i < this.images.length; i++) {
      file.push({
        file_name: this.images[i].img,
        is_default: i == 0 ? true : false,
        type: this.images[i].img.split(';')[0].split('/')[1],
        setting_media_id: this.images[i].setting_media_id ? this.images[i].setting_media_id : null
      })
    }
    if (!file.length) {
      this.notification.error('Error', 'Please Atleast select 1 Image');
      return
    }

    const params = {
      setting_id: +this.settingId,
      featured_price: +this.featuredPrice,
      featured_duration: +this.featuredDuration,
      support_phone: + this.supportPhone,
      file: file,
    }
    this.subscription.add(
      this.settingService.updateSettingData(params).subscribe((res)=>{
        if(res['code'] === 200){
          this.notification.success('Success',res['message']);
          this.getSetting();
        }
        else{
          this.notification.error('Error',res['message']);
        }
      },
      (err)=>{
        console.log(err);
      }
      )
    )
  }


  deleteImgLocal(index: number) {
    this.images.splice(index, 1); 
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push({ img: event.target.result });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


}






