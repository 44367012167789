// tslint:disable:no-any
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router'
import { URLS } from '../_config/api.config';
import { CommonService } from '../_services/common.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class ViewNotificationComponent implements OnInit  {
  public notificationData: any = [];
  public page: number = 1;
  private pageSize: number = 10;
  // flag for mark all as read button loader
  public isMarkAllButtonLoading: boolean = false;

  public routes = [
    {type: "customer profile", routeUrl: 'manage-user', redirectOn: 'view'},
    {type: "event details", routeUrl: 'manage-event', redirectOn: 'view'},
    {type: "inappropriate events listing", routeUrl: 'manage-event-reports', redirectOn: 'list'}
  ]
  // public ds = new MyDataSource(this.http);


  constructor(private http: HttpClient, private router: Router, private commonService: CommonService, private notification: NzNotificationService) {}

  ngOnInit() {
    this.getNotifications();
  }

  public getNotifications() {
    const param = {'limit': this.pageSize.toString(), 'page': this.page.toString()};
    this.http.get(URLS.notification)
    .subscribe(responce => {
      if (responce['code'] === 200) {
        this.notificationData = responce['data']['result'];
      }
    })
  }

  public onNotificationClick(currentItem) {
    this.routes.forEach(route => {
      // filter & redirect to page
      if (currentItem.notification_type === route.type) {
        if (route.redirectOn === 'view') {
          this.router.navigateByUrl(route.routeUrl + '/view/' + currentItem.reference_id );
        } else {
          this.router.navigateByUrl(route.routeUrl);
        }
      }
    })
    if (!currentItem.is_read) {
      const param = {notification_id: currentItem.notification_id, is_read: true};
      this.http.put(URLS.notificationUpdate, param)
      .subscribe(responce => {
        if (responce['code'] === 200) {
          currentItem.is_read = !currentItem.is_read;
          this.commonService.setEvent('notificationUpdate',  true);
        }
      })
    }
  }

  public onScroll(event) {
    // if (event === 5) {
    //   this.page = this.page + 1;
    //   this.getNotifications();
    // }
  }

  public onReadAll() {
    this.isMarkAllButtonLoading = true;
    const param = {isReadAll: true};
    this.http.put(URLS.notificationUpdate, param)
    .subscribe(response => {
      this.isMarkAllButtonLoading = false;
      if (response['code'] === 200) {
        this.notificationData.forEach(data => {
          data.is_read = 1;
        })
        this.commonService.setEvent('notificationUpdate',  true);
        this.notification.success('Success', response['message'] );
      }
    },
    error => {
      console.error(error);
      this.isMarkAllButtonLoading = false;
    })
  }
}

// class MyDataSource extends DataSource<string | undefined> {
//   private length = 100000;
//   private pageSize = 10;
//   private cachedData = Array.from<any>({ length: this.length });
//   private fetchedPages = new Set<number>();
//   private dataStream = new BehaviorSubject<any[]>(this.cachedData);
//   private subscription = new Subscription();
//   public page: number = 0;
//   public notificationCount: number = 0;
//   public enableNext: boolean = true;
//   constructor(private http: HttpClient) {
//     super();
//   }

//   connect(collectionViewer: CollectionViewer): Observable<any[]> {
//     this.subscription.add(
//       collectionViewer.viewChange.subscribe(range => {
//         const startPage = this.getPageForIndex(range.start);
//         const endPage = this.getPageForIndex(range.end - 1);
//         for (let i = startPage; i <= endPage; i++) {
//           this.fetchPage(i);
//         }
//       })
//     );
//     return this.dataStream;
//   }

//   disconnect(): void {
//     this.subscription.unsubscribe();
//   }

//   private getPageForIndex(index: number): number {
//     return Math.floor(index / this.pageSize);
//   }

//   private fetchPage(page: number): void {
//     if (this.fetchedPages.has(page)) {
//       return;
//     }
//     if (this.enableNext) {
//       this.page = this.page + 1;
//       this.fetchedPages.add(page);
  
//       let param = {'limit': '10', 'page': this.page.toString()};
//       // URLS.notification, {params: param}
//       this.http.get(URLS.notification, {params: param})
//         .subscribe((res: any) => {
//           this.enableNext = res.data.enableNext;
//           this.notificationCount = res.data.totalRecords;
//           this.cachedData.splice(page * this.pageSize, this.pageSize, ...res.data.result);
//           this.dataStream.next(this.cachedData);
//         }
//       );
//     }
//   }
// }
