import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';


@Injectable()
export class AddEditCmsService {
  update = URLS.cmsUpdate;
  list = URLS.cmsDetails;
  add = URLS.cmsAdd;
  privacyData = URLS.privacyPolicy;

  updateDetails(dataParams : any): Observable<{}> {
    return this.http.put(`${this.update}` , dataParams );
  }

  getDetails(dataParams : any): Observable<{}> {
    return this.http.get(`${this.list}` , {
      params : dataParams
    });
  }

  addDetails(dataParams : any): Observable<{}> {
    return this.http.post(`${this.add}` , dataParams);
  }

  getPrivacyPolicy(): Observable<{}> {
    return this.http.get(`${this.privacyData}`);
  }

  constructor(private http: HttpClient) {}
}
