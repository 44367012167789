import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';

@Injectable()
export class TableLayoutService {

  list = URLS.eventNotification;
  emailList = URLS.emailMsgSend;
  reportList = URLS.inappropriateeventsList;
  emailReportData = URLS.emailReportData;
  eventTitle = URLS.eventTitle;

  getTableData(url , dataParams?:any): Observable<{}> {
    return this.http.get(url , {
      params: dataParams
    });
  }
  getAutocompleteData(url, dataParams): Observable<{}> {
    return this.http.get(url , {
      params: dataParams
    });
  }
  updateStatus(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  updateTableData(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  deleteData(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  approveFeature(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }
  verifyUserData(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }
  visibleEvent(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }
  featuredEvent(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  getEventNotification(dataParams:any): Observable <{}> {
    return this.http.post(`${this.list}`, dataParams)
  }

  getEmailMsg(dataParams: any): Observable <{}> {
    return this.http.post(`${this.emailList}`, dataParams)
  }

  // getReportList(dataParams: any): Observable <{}> {
  //   return this.http.get(`${this.reportList}`, dataParams)
  // }

  
  getEmailById(reportId: any): Observable<{}> {
    return this.http.post(`${this.emailReportData}`, { report_id: reportId });
  }


  getEventTitle(eventId: any): Observable<{}> {
    return this.http.post(`${this.eventTitle}`, { event_id: eventId });
  }

  eventAccessRequests(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }
  
 
  constructor(private http: HttpClient) {}
}
