import { Component, OnInit } from '@angular/core';
import { URLS } from '../_config/api.config';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor() { }

  column_settings = [
    { primaryKey: 'received_on', header: 'Received On', sort: true},
    { primaryKey: 'user_name', header: 'User Name', sort: true},
    { primaryKey: 'email_id', header: 'Email Id', sort: true},
    { primaryKey: 'mobile_no', header: 'Mobile', sort: true},
    { primaryKey: 'topic', header: 'Title', sort: true},
    { primaryKey: 'faq_question', header: 'Message', sort: true},
    // { primaryKey: 'statua', header: 'Status', status: true },
    { primaryKey: '', header: 'Action', action: true}
  ];
  apiUrl = {
    list: URLS.contactusList,
    updateStatus: URLS.contactusUpdateStatus
  };
  actions = {
    view: true,
    edit: false,
    // delete: true,
    updateStatus: false
  };
  widthConfig = ['150px', '150px', '150px', '150px', '150px', '180px'];
  scrollConfig = { x: '800px', y: '100%' };
  queryParams = { status: 'status', page_id: 'contact_us_id'};
  table_search = [
    {fieldKey: 'date_range', fieldName: 'Date', range: true},
    {fieldKey: 'user_name', fieldName: 'User Name', input: true}
  ]

  // Manipulating Add User button
  // text which is going to display on button
  // URL will be redirect on specific page
  // isVisible checking whether button will be visible or not based on Boolean value passed.
  // public addUser: any = {
  //   text: 'Add User',
  //   url: '/manage-user/add',
  //   isVisible: true
  // }

  
  ngOnInit() {
  }

}
