import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { ContactUsViewService } from './contact-us-view.service';

@Component({
  selector: 'app-contact-us-view',
  templateUrl: './contact-us-view.component.html',
  providers: [ContactUsViewService],
  styleUrls: ['./contact-us-view.component.scss']
})
export class ContactUsViewComponent implements OnInit {
  // id for which to display data
  public displayData: any = {};

  // query params instance
  public dataId: string;

  // managing subscription
  private subscription: Subscription = new Subscription();


  constructor(
    private activeRoute: ActivatedRoute,
    private contactUsViewService: ContactUsViewService,
    private router: Router,
  ) {
    // get id from the query parms
    this.dataId = this.activeRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    // httpParams for handling dynamic query params
    // let httpParams = new HttpParams();
    // // query params for pagination
    // httpParams = httpParams.append('id', this.dataId);
    // api call to get the table data
    this.subscription.add(this.contactUsViewService
      .getContactUsDetail(this.dataId)
      .subscribe((data: any) => {
        // if success then go in loop
        if (data['code'] === 200) {
          // displaying data
          this.displayData = data.data[0];
        }
      }, (error) => {
        // print the error on console
        console.error(error);
      })
    );
  }

  userClick(customer_id) {
    this.router.navigate(['/manage-user/view/' + customer_id]);
  }

}
