import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../_config/api.config';
import { CommonService } from '../_services/common.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})

export class NotificationPopupComponent implements OnInit {
  isPopupVisible: boolean;
  loading =  true;
  count = 0;
  notificationData:any = [];
  public routes = [
    {type: "customer profile", routeUrl: 'manage-user/view'},
    {type: "event details", routeUrl: 'manage-event/view'},
    {type: "inappropriate events listing", routeUrl: 'manage-event-reports'}
  ]

  constructor(private router: Router, private http: HttpClient, private commonService: CommonService) {
  }

  ngOnInit() {
    this.commonService.eventObservable.subscribe(responce => {
      if (responce['event'] === 'notificationUpdate') {
        this.getNotifications();
      }
    })
    this.getNotifications();
  }

  public getNotifications() {
    let param = {'limit': '4', 'page': '1'};
    this.http.get(URLS.notification, {params: param} )
    .subscribe(responce => {
      if (responce['code'] === 200) {
        this.count = responce['data']['totalUnreadRecords'];
        this.notificationData = responce['data']['result'];
      }
    })
  }

  public onNotificationClick(currentItem) {
    this.isPopupVisible = false;
    this.routes.forEach(route => {
      // filter & redirect to page
      if (currentItem.notification_type === route.type) {
        if(currentItem.notification_type == "inappropriate events listing"){
          this.router.navigateByUrl(route.routeUrl + '?report_id=' + currentItem.reference_id );
        }else{
          this.router.navigateByUrl(route.routeUrl + '/' + currentItem.reference_id );
        }
      }
    })
    // if notification is unread
    if (!currentItem.is_read) {
      const param = {notification_id: currentItem.notification_id, is_read: true};
      this.http.put(URLS.notificationUpdate, param)
      .subscribe(responce => {
        if (responce['code'] === 200) {
          currentItem.is_read = !currentItem.is_read;
          this.getNotifications();
        }
      })
    }
  }

  clickMe(): void {
    this.isPopupVisible = false;
    this.router.navigate(['/notification']);
  }

  change() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

}
