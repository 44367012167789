
import { NgModule } from '@angular/core';
import { SettingComponent } from './setting.component';
import { SharedModule } from '../_common/shared.module';

@NgModule({
  declarations: [
    SettingComponent
  ],
  imports: [
    SharedModule
  ]
})
export class SettingModule { }
