import { Injectable } from '@angular/core';
import { URLS } from '../_config/api.config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeatureEventService {
  getFeature = URLS.featureList;
  featureStatus = URLS.approveFeature;


  getFeatureList(params): Observable<{}> {
    return this.http.get(`${this.getFeature}`+params);
  }
  approveFeature(data): Observable<{}> {
    return this.http.put(`${this.featureStatus}`,data );
  }
  constructor(private http: HttpClient) { }
}
