import { Component, OnInit } from '@angular/core';
import { FeatureEventService } from './feature-event.service';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-feature-event',
  templateUrl: './feature-event.component.html',
  styleUrls: ['./feature-event.component.scss'],
  providers: [FeatureEventService]
})
export class FeatureEventComponent implements OnInit {
  public featureList: any;
  private subscription: Subscription = new Subscription();
  size = 'small';
  public is_feature: boolean = false;

  constructor(
    public featureService: FeatureEventService,
    public notification: NzNotificationService,
  ) { }

  ngOnInit() {
    this.getFeatureList();
  }

  public activeFeatured(data) {
    if (data.is_featured == true) {
      const params = {
        request_id: data.request_id,
        event_id: data.event_id,
        status: "accept"
      }
      this.subscription.add(
        this.featureService.approveFeature(params).subscribe(res => {
          if (res['code'] == 200) {
            this.getFeatureList();
          } else {
            this.notification.error('Error', data['message']);
          }
        },
          (err) => { })
      )
    } else {
      const params = {
        request_id: data.request_id,
        event_id: data.event_id,
        status: "reject"
      }
      this.subscription.add(
        this.featureService.approveFeature(params).subscribe(res => {
          if (res['code'] == 200) {
            this.getFeatureList();
          } else {
            this.notification.error('Error', data['message']);
          }
        },
          (err) => {
            console.log(err);
          })
      )
    }
  }

  public getFeatureList() {
    this.subscription.add(
      this.featureService.getFeatureList('?page=1').subscribe((data) => {
        if (data['code'] === 200) {
          this.notification.success('Success', data['message']);
          this.featureList = data['data']['result'];
          if (this.featureList['approval_date'] === null) {
            this.featureList['approval_date'] = "-";
          }
          if (this.featureList['is_featured'] === 0) {
            this.is_feature = false;
          } else {
            this.is_feature = true;
          }
        } else {
          this.notification.error('Error', data['message']);
        }
      },
        (err) => {
          console.log(err);
        }
      ))
  }
}
