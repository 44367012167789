import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponseBase, } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { NzNotificationService } from 'ng-zorro-antd';
import { environment } from 'src/environments/environment';

const CODEMESSAGE = {
  200: 'The server successfully returned the requested data.',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The data was deleted successfully.',
  400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
  401: 'User does not have permission (token, username, password is incorrect).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request is made for a record that does not exist and the server does not operate.',
  406: 'The format of the request is not available.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred when creating an object.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'Gateway error.',
  503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
};

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notification: NzNotificationService, private authService: AuthenticationService, private router: Router) { }

  private checkStatus(ev: HttpResponseBase) {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }
    let checkURL = this.manipulateURLForImageUpload(ev['url']);
    let ignoreErrorTemp = checkURL['first'] === "upload" && checkURL['second'] === "image";
    if (!ignoreErrorTemp) {
      const errortext = CODEMESSAGE[ev.status] || ev.statusText;
      this.notification.error(`Request error${ev.status}: ${ev.url}`, errortext);
    }
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    this.checkStatus(ev);
    switch (ev['body']['code']) {
      case 200:
        break;
      case 202:
        this.notification.warning(`${ev['body']['message']}`, ``);
        break;
      case 400:
        this.notification.error(`${ev['body']['message']}`, ``);
        break;
      case 401:
        // window.location.reload();
        this.notification.error(`${ev['body']['message']}`, ``);
        // localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
        this.authService.logout();
        break;
      case 403:
        this.router.navigate(['/page-not-found']);
      case 404:
      case 410:
        this.notification.error(`${ev['body']['message']}`, ``);
        break;
      case 409:
        this.notification.error(`${ev['body']['message']}`, ``);
        break;
      case 500:
        this.notification.error(`${ev['body']['message']}`, ``);
        // this.router.navigate(['/page-not-found']);
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn(`I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`, ev);
          return throwError(ev);
        }
        break;
    }
    return of(ev);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url;
    const newReq = req.clone({ url });
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        if (event instanceof HttpResponseBase) return this.handleData(event);
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
  
  private manipulateURLForImageUpload(url) {
    let toBeModified = url;
    let splitURL = toBeModified.split('/');
    let lengthOfURL = splitURL.length;
    return { first: splitURL[lengthOfURL - 1], second: splitURL[lengthOfURL - 2] };
  }
}
