import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';


@Injectable()
export class SettingService {
  getSetting = URLS.getSetting;
  updateSetting = URLS.updateSetting;

  getSettingData(): Observable<{}> {
    return this.http.get(`${this.getSetting}`);
  }

  updateSettingData(dataParams : any): Observable<{}> {
    return this.http.post(`${this.updateSetting}` , dataParams );
  }

  constructor(private http: HttpClient) {}
}
