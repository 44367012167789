import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateNotificationComponent } from './create-notification/create-notification.component';

const routes: Routes = [
  {
    path: '',
    component: CreateNotificationComponent,
    data: {
      breadcrumb: 'Create Notification',
      // title: 'Send Push Notification'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationRoutingModule { }
