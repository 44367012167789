import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { NotificationService } from './notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-notification',
  providers: [NotificationService],
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.scss']
})
export class CreateNotificationComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  private subscription: Subscription = new Subscription();

  get f() { return this.form.controls; }

  types: any[] = [
    {
      id: 'EVT',
      name: 'EVENT'
    },
    {
      id: 'EXT',
      name: 'EXTERNAL'
    },
  ];
  events: any[] = [];
  showExternal: boolean = false;
  showEvents: boolean = false;

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
      type: ['', Validators.required],
      externalLink: [''],
      eventId: [''],
    });
  }

  public getEventsList() {

    this.subscription.add(this.notificationService
      .getDropdownList()
      .subscribe((data: any) => {
        if (data['code'] === 200) {
          this.events = data['data'];
        }
      }, (error) => {
        console.error(error);
      })
    );
  }

  add() {
    var param = {
      title: this.f.title.value,
      message: this.f.message.value,
      type: this.f.type.value,
      externalLink: this.f.externalLink.value || '',
      eventId: this.f.eventId.value || '',
    };

    if (this.form.valid) {
      this.subscription.add(this.notificationService
        .sendPushNotification(param)
        .subscribe((response: any) => {
          if (response['code'] === 200) {
            this.notification.success(
              'success',
              response['message']
            );
            this.form.reset();
            this.showEvents = false;
          }
        }, (error) => {
          console.error(error);
        })
      );
    } else {
      this.notification.error('Error', 'Form is invalid');
    }
  }

  onChange() {
    const type = this.form.get('type').value;
    if (type && type == 'EXT') {
      this.showExternal = true;
      this.showEvents = false;
    } else {
      if (this.events.length == 0) {
        this.getEventsList();
      }
      this.showExternal = false;
      this.showEvents = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}