import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { ColumnMap } from '../layout.model';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { TableLayoutService } from './table-layout.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';


@Component({
  selector: 'app-table-layout',
  providers: [TableLayoutService],
  templateUrl: './table-layout.component.html',
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        padding: '16px',
        maxHeight: '800px'
      })),
      state('closed', style({
        opacity: 0,
        padding: 0,
        maxHeight: 0,
        overflow: 'hidden'
      })),
      transition('open => closed', [
        animate('300ms ease-in')
      ]),
      transition('closed => open', [
        animate('300ms ease-out')
      ]),
    ])
  ]
})
export class TableLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: any[];
  @Input() apiUrl: any;
  @Input() actions: any;
  @Input() widthConfig: any[];
  @Input() scrollConfig: any;
  @Input() search: any[];
  @Input() queryParams: any[] = [];
  @Input() addModule: any;
  @Input() isExport: boolean;
  @Input() pageName: any;
  @Input() userlisting: any;
  @Input() eventType: any;


  fixedHeader: Boolean = true;
  multiOptions: any;
  i: number;
  // column key
  columnMaps: ColumnMap[];

  // filter panel initial state
  isOpen = false;

  isVisibleNoti = false;

  isEmailNoti = false;
  public selectedReportId: string;

  public selectedEventId: string;

  /**
   * filter panel toggle function
   * */
  toggle() {
    this.isOpen = !this.isOpen;
  }

  // managing subscription
  private subscription: Subscription = new Subscription();

  // confirmation modal instance
  private confirmModal: NzModalRef;

  // table default params
  public tableConfig = {
    'page': 1,
    'limit': 20
  };

  // table data loading status
  public loading = false;

  // array to hold the table data
  public listOfAllData: any[] = [];

  // object to store custom table configuration
  public tableDataConfig = {};

  public is_feature: any;

  //form instance
  public validateForm: FormGroup;

  public autocompleteData: any = [];

  /**
   * function to implemnt sorting on table
   * @param key to hold the table header key
   * @param value to hold the table sorting type
   */
  public sort(sort: { key: string; value: string }): void {
    // preparing dynamic params based on sorting
    this.tableConfig['sort_by'] = sort.key;
    this.tableConfig['sort_type'] = sort.value === 'descend' ? 'desc' : 'asc';
    // if no results found then don't call the api
    if (this.listOfAllData.length) {
      this.getData(true, false, this.eventType);
    }
  }

  /**
    * function to reset the form
   */
  public resetForm(): void {
    // reset the form
    this.validateForm.reset();
    // if reset set the page to 1
    this.tableConfig['page'] = 1;
    // list all data
    this.getData(true, false, this.eventType);
  }

  /**
   * function to naivgate to detail pages
   * @param viewName to hold the router link
   * @param dataSource to hold the router params
   */
  public navigateRow(viewName: string, dataSource: number): void {
    this.router.navigate([viewName, dataSource], { relativeTo: this.activeRoute });
  }

  pushForm: FormGroup;

  emailForm: FormGroup;

  selectedEmail: string;
  user_name: string;

  selectedEventTitle: string;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private tableLayoutService: TableLayoutService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private activeRoute: ActivatedRoute,
  ) {
    this.pushForm = this.fb.group({
      title: [''],
      message: [''],
    });
    this.emailForm = this.fb.group({
      type: [''],
      message: [''],
    });
  }


  showModal(eventId: string) {
    this.isVisibleNoti = true;
    this.selectedEventId = eventId;

    this.getEventTitleData(eventId);

  }
  async getEventTitleData(eventId: string) {
    try {
      const response = await this.tableLayoutService.getEventTitle(eventId).toPromise();
      if (response['code'] === 200) {
        const event_title = response['data']['event_title'];
        this.selectedEventTitle = event_title;
        this.pushForm.get('title').setValue(event_title);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Function to handle form submission
  handleOk() {
    if (this.pushForm.valid) {
      // Extract "title" and "message" from the form
      const title = this.pushForm.get('title').value;
      const message = this.pushForm.get('message').value;

      // Send the "title" and "message" to your backend API
      const data = {
        title: title,
        message: message,
      };

      this.subscription.add(this.tableLayoutService
        .getEventNotification(data)
        .subscribe((response: any) => {
          if (response['code'] === 200) {
            this.notification.success(
              'success',
              response['message']
            );
          }
        }, (error) => {
          console.error(error);
        }));

      // Close the modal or form
      this.isVisibleNoti = false;

      // Optionally, reset the form
      this.pushForm.reset();
    }
  }

  handleCancel(): void {
    this.isVisibleNoti = false;
  }

  getEmailForReport(reportId: string) {
    this.subscription.add(
      this.tableLayoutService.getEmailById(reportId).subscribe((response: any) => {
        if (response['code'] === 200) {
          const email = response['data']['email'];
          const user = response['data']['user_name'];
          this.selectedEmail = email;
          this.user_name = user;
          this.emailForm.get('type').setValue(email);
        }
      }, (error) => {
        console.error(error);
      })
    );
  }


  showEmailModal(reportId: string) {
    this.isEmailNoti = true;
    this.selectedReportId = reportId;

    this.emailForm.get('type').setValue(this.selectedEmail);

    this.getEmailForReport(reportId);
  }

  emailOk() {
    if (this.emailForm.valid) {
      const message = this.emailForm.get('message').value;

      const data = {
        report_id: this.selectedReportId,
        type: this.selectedEmail,
        message: message,
        user_name: this.user_name,
      };

      this.subscription.add(this.tableLayoutService
        .getEmailMsg(data)
        .subscribe((response: any) => {
          if (response['code'] === 200) {
            this.notification.success(
              'success',
              response['message']
            );
            this.emailForm.reset();
          }
        }, (error) => {
          console.error(error);
        }));
      this.isEmailNoti = false;
      this.pushForm.reset();
    }
  }


  emailCancel(): void {
    this.isEmailNoti = false;
  }


  //convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  /**
  * function to update the status
  * @param data hold the table data instance
  */
  public updateTable(data) {
    // params send to change status
    let httpParams: { [k: string]: any } = {};
    // httpParams[this.queryParams['status']] = (data[this.queryParams['status']] === 0 ? 1 : 0);
    httpParams[this.queryParams['status']] = ((data.is_active || 0) + (data.status|| 0)) ? 0: 1;
    httpParams[this.queryParams['page_id']] = data['page_id'];
    // status loading  indicator
    data['switchLoading'] = true;
    // table data loading status
    this.loading = true;
    // api call to update the status

    this.subscription.add(this.tableLayoutService.updateStatus(this.apiUrl.updateStatus, httpParams).subscribe((res) => {
      // if success then go in loop
      if (res['code'] === 200) {
        // change status
        data['status'] = !data['status'];
        // success modal when status updated
        this.notification.create(
          'Success',
          'Status changed',
          'Status has been updated successfully.'
        );
      }
      // destroy the confirm modal instance
      this.confirmModal.destroy();
      // status loading  indicator
      data['switchLoading'] = false;
      // table data loading status
      this.loading = false;
    }, (error) => {
      // destroy the confirm modal instance
      this.confirmModal.destroy();
      // status loading  indicator
      data['switchLoading'] = false;
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }

  /**
   * function to confirm the status
   * @param data table data instance
   */
  public showConfirm(data: any): void {
    const msgTitle = (data['status']) ? 'Deactive' : 'Active';
    this.confirmModal = this.modal.confirm({
      // ${data.is_active === 0 ? 'Inactive' : 'Active'}
      nzTitle: `Do you want to change the status to ${msgTitle}?`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // function call to update the status
          this.updateTable(data);
        }).catch(() => { console.log('Oops errors!') }),
      nzOnCancel: () => { this.loading = false; data['switchLoading'] = false; }
    });
  }

  /**
  * function to confirm the status of Verified User
  * @param data table data instance
  */
  public showVerifiedUser(data: any): void {
    const msgTitle = (data['status']) ? 'Deactive' : 'Active';
    this.confirmModal = this.modal.confirm({
      // ${data.is_active === 0 ? 'Inactive' : 'Active'}
      nzTitle: `Do you want to change the status to ${msgTitle}?`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // function call to update the status
          if (data['is_verified_profile'] != 0) {
            data['is_verified_profile'] = 0;
          }
          else {
            data['is_verified_profile'] = 1;
          }
          const params = {
            customer_id: data['customer_id'],
            is_verified_profile: data['is_verified_profile'],
          }
          this.subscription.add(
            this.tableLayoutService.verifyUserData(this.apiUrl.verifyUser, params).subscribe((res) => {
              if (res['code'] == 200) {
                this.updateTable(data);
              }
            },
              (err) => {
                console.log(err);
              })
          )
        }).catch(() => { console.log('Oops errors!') }),
      nzOnCancel: () => { this.loading = false; data['switchLoading'] = false; }
    });
  }


  ngOnInit(): void {
        this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.fixedHeader = false;
        } else {
          this.fixedHeader = true;
        }
      });
    // function call to load data when page loads
    this.getData(true, false, this.eventType);
  }

  public getAutocompleteData(value: string) {
    let param = {
      dropdown: true,
      leader_name: value
    };
    // api call to get the table data
    this.subscription.add(this.tableLayoutService
      .getAutocompleteData(this.apiUrl.autocomplete, param)
      .subscribe((responce: any) => {
        if (responce['code'] === 200) {
          this.autocompleteData = responce['data'];
        }
      })
    )
  }

  public formatDate(dates) {
    dates.forEach((date, index) => {
      if (typeof date !== 'string') {
        const dd = (date.getDate().toString().length === 1) ? '0' + date.getDate() : date.getDate();
        let mm = date.getMonth() + 1;
        mm = (mm.toString().length === 1) ? '0' + mm : mm;
        const yyyy = date.getFullYear();
        dates[index] = yyyy + '-' + mm + '-' + dd;
      }
    });
  }

  public downloadData(file, fileName) {
    const a = document.createElement('a')
    a.href = file
    a.download = fileName;
    a.click();
  }

  /**
   *
   * @param fromFilter If user search from advance filter do not pass page number
   */
  public getData(fromFilter: boolean, isExport: boolean = false, isPublic: any = 0): void {
    // httpParams for handling dynamic query params
    let httpParams = new HttpParams();
    let copyOfTableConfig = this.tableConfig;
    // query params for pagination
    if (!fromFilter) delete copyOfTableConfig['page'];
    for (const key of Object.keys(copyOfTableConfig)) {
      httpParams = httpParams.append(key, copyOfTableConfig[key]);
    }
    // get form keys and value
    const formKeys = Object.keys(this.f),
      formValue = this.f,
      searchBy = [],
      searchValue = [];
    // create query params based on form value
    for (let keys of formKeys) {
      const appendValues = formValue[keys]['value'];
      if (appendValues) {
        searchBy.push(keys);
        if (typeof appendValues === 'string') {
          searchValue.push(appendValues.trim().replace(/,/g, '#x2c'));
        } else {
          if (typeof appendValues === 'object') {
            this.formatDate(appendValues);
          }
          searchValue.push(appendValues);
        }
      }
    }
    if (httpParams.has('page') !== true) httpParams = httpParams.append('page', '1');
    for (let i = 0; i < searchBy.length; i++) {
      httpParams = httpParams.append(searchBy[i].toString(), searchValue[i].toString());
    }

    if (isExport) {
      httpParams = httpParams.append('isExport', '1');
    }

    // table data loading status
    this.loading = true;
    //
    const urlCall = (isPublic == 1) ? this.apiUrl.publicEvent : (isPublic == 2) ? this.apiUrl.privateEvent : this.apiUrl.list
    // api call to get the table data
    this.subscription.add(this.tableLayoutService.getTableData(urlCall, httpParams).subscribe((res) => {
      // if data present then only goes in the loop
      if (res['code'] === 200) {
        if (!isExport) {
          // displaying data in table
          this.listOfAllData = res['data']['result'];
          if (this.pageName === "event_list") {
            this.listOfAllData.forEach((element, index) => {
              if (element['is_featured'] === 1) {
                this.is_feature = "Featured";
              } else {
                this.is_feature = "Not Featured";
              }
            });
          }
          if (this.userlisting === "active") {
            this.listOfAllData.forEach((element, index) => {
              if (index > 1) {
                element['validuser'] = 'active';
              } else {
                element['validuser'] = 'deactive';
              }
            });
          }
          // logic to manipulate field keys for queryparams
          for (const data of this.listOfAllData) {
            data['page_id'] = data[this.queryParams['page_id']];
            // handling special case for status of manage inappropriate report
            if (data['report_id']) {
              data['status'] = data[this.queryParams['status']];
            }
          }
          // storing the totalrecord for pagination
          this.tableDataConfig['totalRecords'] = res['data']['totalRecords'];
        } else {
          const file = res['data'].file;
          const fileName = res['data'].fileName;
          this.downloadData(file, fileName);
        }
      }
      // table data loading status
      this.loading = false;
    }, (error) => {
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }


  // To redirect on add page.
  public redirectAdd(): void {
    this.router.navigate([this.addModule.url]);
  }

  ngOnChanges() {
    if (this.settings) {
      this.columnMaps = this.settings.map(col => new ColumnMap(col));
    } else {
      this.columnMaps = Object.keys(this.listOfAllData[0]).map(key => {
        return new ColumnMap({ primaryKey: key });
      });
    }
    // prepare form group
    this.validateForm = this.fb.group({});
    if (this.search) {
      for (let i = 0; i < this.search.length; i++) {
        this.validateForm.addControl(this.search[i].fieldKey, new FormControl());
        if (this.search[i].isSelect) {
          this.validateForm.get(this.search[i].fieldKey).setValue(this.search[i].isSelect + '')
        }
      }
    }
  }


  deleteData(dataId: any): void {
    let httpParams = {};
    httpParams[this.queryParams['page_id']] = dataId;
    httpParams['is_declined'] = 1;
    this.loading = true;
    this.subscription.add(this.tableLayoutService.updateStatus(this.apiUrl.updateStatus, httpParams).subscribe((res) => {
      // if data present then only goes in the loop
      if (res['code'] === 200) {
        this.notification.success('Success', 'Report has been deleted successfully.');
        this.getData(true, false, this.eventType);
      }
      // table data loading status
      this.loading = false;
    }, (error) => {
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));
  }

  isVisible(data) {
    const params = {
      event_id: data['event_id'],
      is_visible: data['is_visible']
    }
    this.subscription.add(
      this.tableLayoutService.visibleEvent(this.apiUrl.visibleEvent, params).subscribe((res) => {
        if (res['code'] === 200) {
          this.notification.success('Success', res['message']);
        } else {
          this.notification.error('Error', res['message']);
        }
      },
        (err) => {
          console.log(err);
        })
    )
  }

  isFeatured(data) {
    const params = {
      event_id: data['event_id'],
      is_featured: data['is_featured'],
    }
    this.subscription.add(
      this.tableLayoutService.featuredEvent(this.apiUrl.featureEvent, params).subscribe((res) => {
        if (res['code'] === 200) {
          this.notification.success('Success', res['message']);
        } else {
          this.notification.error('Error', res['message']);
        }
      },
        (err) => {
          console.log(err);
        })
    )
  }

  // on destroy unsubscribe all the subscription
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  btn(data, value: 'A' | 'D') {
    const params = {
      event_access_requests_id: data['event_access_requests_id'],
      status: value == 'A' ? 'accept' : 'decline'
    }
    this.subscription.add(
      this.tableLayoutService.eventAccessRequests(this.apiUrl.updateRequest, params).subscribe((res) => {
        if (res['code'] === 200) {
          this.notification.success('Success', res['message']);
        } else {
          this.notification.error('Error', res['message']);
        }
        this.getData(true, false, this.eventType);
      },
        (err) => {
          console.log(err);
        })
    )
  }

}
