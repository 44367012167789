import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { AuthenticationService } from '../_services/authentication.service';
import { CustomValidator } from '../_common/custom-validators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isLoadingOne = false;
  public passwordVisible = false;
  public password: string;
  public validateForm: FormGroup;
  public submitted = false;
  private returnUrl: string;

  public validation_messages = {
    'userName': [
      { type: 'required', message: 'Please input your username!' },
      { type: 'invalidString', message: 'Whitespace should not be included' },
    ],
    'password': [
      { type: 'required', message: 'Please input your Password!' }
    ]
  }

  constructor(
    private fb: FormBuilder, private router: Router,
    private notification: NzNotificationService,
    private activeRoute: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    ) {
    let isLoggedIn;
    this.authService.isLoggedIn()
      .subscribe((data) => {
        isLoggedIn = data;
      }
      );
    if (isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null,
        {
          validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
        }
      ],
      password: [null,
        {
          validators: Validators.compose([Validators.required]),
        }],
      // remember: [true]
    });
    this.returnUrl = this.activeRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  public submitForm() {
    for (const i in this.f) {
      this.f[i].markAsDirty();
      this.f[i].updateValueAndValidity();
    }
    this.submitted = true;
    if (this.validateForm.invalid) { return; }
    const params = {
      'user_name': this.f.userName.value,
      'password': this.f.password.value
    }

    this.isLoadingOne = true;
    localStorage.removeItem('token');

    const url = window.location.hostname;
    const vendorUrls = ['vendor-dev.getpiqr.co', 'vendor-cp.getpiqr.co']

    if (vendorUrls.includes(url)) {
      this.authService.vendorlogin(params)
        .subscribe(res => {
          if (res && res['code'] === 200) {
            this.router.navigateByUrl(this.returnUrl);
            this.notification.create('success', `Congrats`, 'You have been logged in suceesfully..');
            this.validateForm.reset();
          } else if (res['code'] === 405) {
            this.notification.error('Error', res['message']);
          }
          this.isLoadingOne = false;
        },
          error => {
            this.isLoadingOne = false;
            console.error(error);
          }
        );
    } else {
      this.authService.login(params)
        .subscribe(res => {
          if (res && res['code'] === 200) {
            this.router.navigateByUrl(this.returnUrl);
            this.notification.create('success', `Congrats`, 'You have been logged in suceesfully..');
            this.validateForm.reset();
          } else if (res['code'] === 405) {
            this.notification.error('Error', res['message']);
          }
          this.isLoadingOne = false;
        },
          error => {
            this.isLoadingOne = false;
            console.error(error);
          }
        );
    }
  }

}
