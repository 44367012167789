import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';


@Injectable()
export class NotificationService {
  sendPush = URLS.sendNotification;
  dropdownData = URLS.dropdownData;

  sendPushNotification(dataParams: any): Observable<{}> {
    return this.http.post(`${this.sendPush}`, dataParams);
  }

  getDropdownList(): Observable<{}> {
    return this.http.get(`${this.dropdownData}`);
  }

  constructor(private http: HttpClient) { }
}
