import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';


@Injectable()
export class SeatingArrangementService {
  list = URLS.getTicketTier;
  addList = URLS.addSeatingArrangement;
  
  getTicketDetails(dataParams : any): Observable<{}> {
    return this.http.get(`${this.list}` , {
      params : dataParams
    });
  }


  addSeatingArrangement(dataParams: any): Observable<{}> {
    return this.http.post(`${this.addList}`, dataParams);
  }
  

  constructor(private http: HttpClient) {}
}



