import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, AfterViewInit, Renderer2, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SeatingArrangementService } from './seating-arrangement.service';
import { NzNotificationService } from 'ng-zorro-antd';


function arraysEqual(a: any[], b: any[]): boolean {
  return a.length === b.length && a.every((value, index) => value === b[index]);
}

@Component({
  selector: 'app-seating-arrangement',
  templateUrl: './seating-arrangement.component.html',
  styleUrls: ['./seating-arrangement.component.scss'],
  providers: [SeatingArrangementService]
})
export class SeatingArrangementComponent implements OnInit {
  arenaPass: string = '';
  submitted: boolean = false;
  enteredPattern: string = '';
  seats: string[][] = [];
  patternConfirmed: boolean = false;
  showSubmitButton: boolean = true;
  showConfirmButton: boolean = false;
  showSaveButton: boolean = false;
  showGoBackButton: boolean = false;
  // selectedSeats: string[] = [];
   public dataId: string;
   public ticketDetails: any[] = []; 
   private subscription: Subscription = new Subscription();
   saveBox: boolean = false;
   formSaved: boolean = false;

 
  public seatTypes: { name: string;  color: string;  count: number; dropdownOptions: string[]; selectedOption: string; ticketPrice: number }[] = [];

  public selectedType: { [key: string]: string } = {};

  submitForm() {
    if (this.isValid()) {
      this.enteredPattern = this.arenaPass;
      this.generateSeats();

      this.submitted = true;
      this.showSubmitButton = false;
      this.showConfirmButton = true;
      this.showGoBackButton = true; 
    }
  }


  confirmPattern() {
    this.patternConfirmed = true;
    this.calculateSeatTypes();
    this.showConfirmButton = true;
    this.showSaveButton = true;
    this.showGoBackButton = true; 
  }

  goBack() {
    if (this.patternConfirmed) {
      this.submitted = false;
      this.showSubmitButton = true;
      this.showConfirmButton = false;
      this.showSaveButton = false;
      this.showGoBackButton = false;
      this.patternConfirmed = false;
    } else {
      this.submitted = false;
      this.showSubmitButton = true;
      this.showConfirmButton = false;
      this.showSaveButton = false;
      this.showGoBackButton = false;
      this.patternConfirmed = false;
    }
  }

  calculateSeatTypes() {
    this.seatTypes = [];
    const seatTypeCounts = new Map<string, number>();
    const seatTypeMatches = this.enteredPattern.match(/[a-z]/g);
  
    if (seatTypeMatches) {
      seatTypeMatches.forEach(type => {
        seatTypeCounts.set(type, (seatTypeCounts.get(type) || 0) + 1);
      });
    }
  
    const ticketTypes = Array.from(seatTypeCounts.keys());
  
    this.seatTypes = ticketTypes.map(type => ({
      name: type,
      count: seatTypeCounts.get(type) || 0,
      dropdownOptions: this.getDropdownOptions(type),
      selectedOption: '',  
      ticketPrice: 0 ,
      color: '#FDF2D3',

    }));
  }

  getTicketPrice(ticketType: string): number {
    const ticket = this.ticketDetails.find(ticket => ticket.ticket_type_name === ticketType);
    return ticket ? ticket.ticket_price_amount : 0;
  }
  

  getDropdownOptions(ticketType: string): string[] {
    const uniqueTicketTypes = Array.from(new Set(this.ticketDetails.map(ticket => ticket.ticket_type_name)));
    return uniqueTicketTypes;
  }


 onDropdownChange(seatType: any) {
  const selectedOption = seatType.selectedOption;

  seatType.selectedOption = selectedOption;

  const ticket = this.ticketDetails.find(
    ticket =>
      ticket.ticket_type_name === selectedOption
  );

  seatType.ticketPrice = ticket ? ticket.ticket_price_amount : 0;

  this.cdr.detectChanges();
}

  
  


validateMaxCounts(): boolean {
  let isValid = true;

  for (const seatType of this.seatTypes) {
    const ticket = this.ticketDetails.find(
      t => t.ticket_type_name === seatType.selectedOption
    );

    if (ticket) {
      if (seatType.count !== ticket.max_count) {
        isValid = false;
        break;
      }
    }
  }

  if (!isValid) {
    console.log('Count should be equal to the maximum count');
  }

  return isValid;
}

// validateMaxCounts(): boolean {
//   let isValid = true;

//   for (const seatType of this.seatTypes) {
//     const ticket = this.ticketDetails.find(
//       t => t.ticket_type_name === seatType.selectedOption
//     );

//     if (ticket) {
//       if (seatType.count == ticket.max_count) {
//         isValid = false;
//         console.log(`Count for ${seatType.selectedOption} exceeds the maximum count`);
//         break;
//       }
//     }
//   }

//   return isValid;
// }


// validateMaxCounts(): boolean {
//   let isValid = true;

//   // Create a map to store total counts for each seat type
//   const seatTypeTotalCounts = new Map<string, number>();

//   for (const seatType of this.seatTypes) {
//     const ticket = this.ticketDetails.find(
//       t => t.ticket_type_name === seatType.selectedOption
//     );

//     if (ticket) {
//       // Increment the total count for the seat type in the map
//       seatTypeTotalCounts.set(seatType.selectedOption, (seatTypeTotalCounts.get(seatType.selectedOption) || 0) + seatType.count);

//       // Check if both the total count and individual count are equal to the maximum count
//       if (seatTypeTotalCounts.get(seatType.selectedOption) !== ticket.max_count || seatType.count !== ticket.max_count) {
//         isValid = false;
//         console.log(`Total count or individual count for ${seatType.selectedOption} does not match the maximum count`);
//         break;
//       }
//     }
//   }

//   return isValid;
// }



validateSeatTypeSelection(): boolean {
  let isValid = true;
  const selectedSeatTypes = this.seatTypes.map(entry => entry.selectedOption);
  const definedTicketTypes = this.getTicketTypes();
  const additionalSeatTypes = selectedSeatTypes.filter(type => !definedTicketTypes.includes(type));
  const missingSeatTypes = definedTicketTypes.filter(type => !selectedSeatTypes.includes(type));

  if (additionalSeatTypes.length > 0 || missingSeatTypes.length > 0) {
    isValid = false;
    console.log('Validation error: Seat types mismatch');
  }

  return isValid;
}


saveForm() {
  if (this.isValid() && this.validateMaxCounts() && this.validateSeatTypeSelection()) {
    this.notification.blank('Success', 'Form saved successfully');

    const seatTypeParams = this.seatTypes.map(seatType => ({
      name: seatType.name,
      selectedOption: seatType.selectedOption,
      color: seatType.color,
    }));

    const param = {
      enteredPattern: this.enteredPattern,
      seatTypes: seatTypeParams,
    };

    this.subscription.add(this.seatingArrangementService
      .addSeatingArrangement(param)
      .subscribe((response: any) => {
        if (response['code'] === 200) {
          this.notification.success(
            'success',
            response['message']
          );
        }
      }, (error) => {
        console.error(error);
      }));


    if (this.isValid()) {
      this.enteredPattern = this.arenaPass;
      this.generateSeats();

      this.saveBox = true;
      this.formSaved = true;
    }
  } else {
    console.log('Validation error: Maximum count exceeded or invalid seat type selection');

    this.notification.blank('Error', 'Max count & count or invalid seat type & type selection');
  }
}


getSeatColor(row: number, col: number): string {
  const seatNumber = this.getSeatNumber(row, col);
  const seatType = this.seatTypes.find((seatType) => seatType.name === seatNumber.charAt(0));

  return seatType ? seatType.color : ''; // Return the color from seatType or an empty string if not found
}



  isValid(): boolean {
    return true;
  }

  replaceUnderscores(pattern: string): string {
    return pattern.replace(/_/g, '□');
  }
  
generateSeats() {
  this.seats = [];
  const rows = this.arenaPass.split('\n');
  const seatTypeCounts = new Map<string, number>();

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const seatsInRow = row.split('');
    const seatRow = seatsInRow.map((seat, j) => {
      if (seat !== '_') {
        seatTypeCounts.set(seat, (seatTypeCounts.get(seat) || 0) + 1);
        return `${seat}${seatTypeCounts.get(seat)}`;
      } else {
        return '_';
      }
    });

    this.seats.push(seatRow);
  }
}

  

getTicketPriceForSeat(row: number, col: number): number {
  const seatNumber = this.getSeatNumber(row, col);
  const seatType = this.seatTypes.find((type) => type.name === seatNumber.charAt(0));
  return seatType ? seatType.ticketPrice : 0;
}


getSelectedSeatType(row: number, col: number): string {
  const seatNumber = this.getSeatNumber(row, col);
  const seatType = this.seatTypes.find(type => type.name === seatNumber.charAt(0));
  return seatType ? seatType.selectedOption : '';
}





  // selectSeat(row: number, col: number) {
  //   const seat = this.getSeatNumber(row, col);
  //   if (seat !== '_' && !this.selectedSeats.includes(seat)) {
  //     this.selectedSeats.push(seat);
  //   } else {
  //     this.deselectSeat(seat);
  //   }
  // }

  // deselectSeat(seat: string) {
  //   const index = this.selectedSeats.indexOf(seat);
  //   if (index !== -1) {
  //     this.selectedSeats.splice(index, 1);
  //   }
  // }

  // isSeatSelected(row: number, col: number): boolean {
  //   const seat = this.getSeatNumber(row, col);
  //   return this.selectedSeats.includes(seat);
  // }

  getSeatNumber(row: number, col: number): string {
    return this.seats[row][col];
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private seatingArrangementService: SeatingArrangementService,
    private cdr: ChangeDetectorRef,
    private notification: NzNotificationService
  ) {
    this.dataId = this.activeRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getData();
  }

 
  private getData(): void {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('event_id', this.dataId);
    this.subscription.add(this.seatingArrangementService
      .getTicketDetails(httpParams)
      .subscribe((data: any) => {
        if (data['code'] === 200) {
          this.ticketDetails = data.data; 
          this.updateSeatTypes();
        }
      }, (error) => {
        console.error(error);
      })
    );
  }
 

  updateSeatTypes() {
    const ticketTypes = this.getTicketTypes();
    this.seatTypes = ticketTypes.map(type => ({
      name: type,
      count: 0, 
      dropdownOptions: this.getDropdownOptions(type),
      selectedOption: '',  
      ticketPrice: 0 ,
      color: '#FDF2D3',
    }));
  }
  
  getTicketTypes(): string[] {
    return this.ticketDetails.map(ticket => ticket.ticket_type_name);
  }


  updateColor(seatType: any) {
    seatType.color = seatType.color;
  }


  
  

}